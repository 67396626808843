<template>
  <div v-if="showSignIn">
    <div id="wrapper">
      <!-- <div id="baner">
        <h1 class="text-center">Cześć!</h1>
      </div> -->

      <v-container id="textOnBaner">
        <v-row>
          <v-col>
            <v-form>
              <v-card>
                <v-card-title>Logowanie</v-card-title>
                <v-card-text>
                  <v-alert type="error" v-if="errMessage">
                    {{ errMessage }}
                  </v-alert>
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    hint="Email, którym zarejestrowałeś(aś) się podczas zapisywania na zajęcia stacjonarne"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    label="Hasło"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" width="150px" @click="login">
                    Zaloguj
                  </v-btn>
                  <v-btn text @click="resetPassDialog = true"
                    >Zapomniałeś(aś) hasła?</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>

            <v-card class="mt-5">
              <v-card-title>Nie ustawiłeś(aś) jeszcze hasła?</v-card-title>
              <v-card-actions
                ><v-btn to="/onboarding" text color="primary"
                  >Ustaw hasło</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- RESTET PASSWORD -->
    <v-dialog v-model="resetPassDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Zresetuj hasło</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            label="Email"
            v-model="email"
            :rules="emailRules"
            hint="Email, którym zarejestrowałeś(aś) się podczas zapisywania na zajęcia stacjonarne"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetPassDialog = false"> Zamknij </v-btn>
          <v-btn color="primary" text @click="sendPasswordRestLink">
            Wyślij link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <div
    v-else
    style="width: 100%; height: 200px"
    @click.right="showSignIn = true"
  ></div>
</template>

<script>
import firebase from "firebase";
import db from "@/firebase/init";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      ...mapMutations(["changeNavVisibility", "changeMinMode"]),
      email: "",
      password: "",
      errMessage: "",
      resetPassDialog: false,
      showSignIn: false,
      goTo: "/dashboard",
      nameRules: [
        (value) => !!value || "To pole jest wymagane.",
        (value) => (value || "").length <= 20 || "Za długie to imię...",
      ],
      emailRules: [
        (value) => !!value || "To pole jest wymagane.",
        (value) => (value || "").length <= 60 || "Za długi ten email",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Nieprawidłowy e-mail.";
        },
      ],
    };
  },
  // hide navigation when mounted
  mounted() {
    if (this.$route.params.goTo) this.goTo = this.$route.params.goTo;
    this.changeNavVisibility(false);
    this.changeMinMode(true);
  },
  destroyed() {
    this.changeNavVisibility(true);
    this.changeMinMode(false);
  },
  // when form sent...
  methods: {
    login: function() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          () => {
            this.$root.$emit("showNav");
            this.$router.replace(this.goTo);
          },
          () => {
            this.throwErrAlert("Podałeś(aś) nieprawidłowy email lub hasło.");
          }
        );
    },
    sendPasswordRestLink() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          alert("Wysłaliśmy do ciebie maila z linkiem resetowania hasła.");
        })
        .catch(() => {
          alert("Wystąpił błąd. Sprawdź, czy podałeś(aś) prawidłowy email.");
        });
    },
    hideNav() {
      if (this.email == 2) {
        console.log(db);
      }
      this.$root.$emit("hideNav");
    },

    throwErrAlert(text) {
      this.errMessage = text;
    },
  },
};
</script>

<style>
#baner {
  width: 100vw;
  height: 350px;
  background: rgba(131, 58, 180, 1);
  color: white;
}
#textOnBaner {
  transform: translateY(-70px);
}
#baner h1 {
  text-align: center;
  display: block;
  font-size: 6rem;

  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  #baner h1 {
    font-size: 54px;
  }
}
</style>
